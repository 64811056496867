import React, { Suspense, lazy } from 'react';

import { Routes, Route, Navigate } from 'react-router-dom';

import LayoutStart from '../components/layout/LayoutStart';

import useGeneral from '../hooks/useGeneralContext';

import TopNav from '../components/views-components/TopNav';

import '../assets/css/style.css';

import '../assets/css/style-navbar.css';

const RoutesApp = () => {

    const {
        sk,
        hpy,
        ss
    } = useGeneral();

    if(!sk && !hpy && !ss){
        const Login = lazy(() => import('../components/views/Login'));
    
        return(
            <Suspense fallback={<div>Loading...</div>}>
                <Routes>
                    <Route path='/' element={<LayoutStart />}>
                        <Route key='init-path' path='' element={<Login />} />
                    </Route>
                    <Route path="*" element={<Login />} />
                </Routes>
            </Suspense>
        )
    }else{

        const Dashboard = lazy(() => import('../components/views/Dashboard'));
        const Recibos = lazy(() => import('../components/views/Recibos'));
        const Salidas = lazy(() => import('../components/views/Salidas'));
        const Wizard = lazy(() => import('../components/views/Wizard'));

        return(
            <Suspense fallback={<div>Loading...</div>}>
                <TopNav>
                    <Routes>
                        <Route key='dashboard-path' path='/' element={<Recibos />} />
                        <Route key='recibos-path' path='/recibos' element={<Recibos />} />
                        <Route key='salidas-path' path='/salidas' element={<Salidas />} />
                        <Route key='wizard-path-inbound' path='/recibos/new_report' element={<Wizard/>} />
                        <Route key='wizard-path-outbound' path='/salidas/new_report' element={<Wizard/>} />
                    </Routes>   
                </TopNav>
            </Suspense>
        )
    }

}

export default RoutesApp;