import { useCallback, useContext } from 'react';
import ContextGeneral from '../context/GeneralContext';

export default function useGeneral() {
    const { sktoken, setSktoken, hpytoken, setHpytoken, sstoken, setSstoken, config_usr, setConfigUser } = useContext(ContextGeneral);
    
    const access = useCallback((sktoken, hpytoken, sstoken, config_usr) => {
        localStorage.setItem('_WT_SKT', sktoken)
        localStorage.setItem('_WT_HPY', hpytoken)
        localStorage.setItem('_WT_SS', sstoken)
        localStorage.setItem('_WTCG', config_usr)

        setSktoken(sktoken)
        setHpytoken(hpytoken)
        setSstoken(sstoken)
        setConfigUser(config_usr)
    }, [sktoken, setSktoken, hpytoken, setHpytoken, sstoken, setSstoken, config_usr, setConfigUser])

    const logout = useCallback((sktoken, hpytoken, sstoken, config_usr) => {
        localStorage.removeItem('_WT_SKT')
        localStorage.removeItem('_WT_HPY')
        localStorage.removeItem('_WT_SS')
        localStorage.removeItem('_WTCG')
        localStorage.removeItem('_dataShipmentsNew')
        localStorage.removeItem('pageViewUpload')

        setSktoken(null)
        setHpytoken(null)
        setSstoken(null)
        setConfigUser(null)
    }, [sktoken, setSktoken, hpytoken, setHpytoken, sstoken, setSstoken, config_usr, setConfigUser])

    return {
        sk: sktoken,
        hpy: hpytoken,
        ss: sstoken,
        configusr: JSON.parse(config_usr),
        setConfig: setConfigUser,
        sT: setSktoken,
        access,
        logout
    }
}