import React from "react";
import useGeneral from '../../../src/hooks/useGeneralContext';
import Logo from '../../assets/img/logotipo-wetrack.png';
import LogoMobile from '../../assets/img/logotipo-wetrack-mobile.png';
import '../../assets/css/style-header.css';
import ICONS from '../layout/icons/icons';

const Header = ({ isOpen, toggle, toggleButtonRef }) => {

    const {
        configusr,
    } = useGeneral();

    const getLetter = (email) =>{
        const name = email.charAt(0);
        return name.toUpperCase();
    }

    return(
        <header className={`headerContainer ${isOpen ? '' : 'isClosed'}`}>
            <div className="headerLogo">
                <img src={Logo} alt="" className="LogoDesk"/>
                <div className="menuMobile" onClick={toggle} ref={toggleButtonRef}>{ICONS['menu']['menu-mobile']}</div>
                <img src={LogoMobile} alt="" className="LogoMobile"/>
            </div>
            <div className="headerInfo">
                <div>
                    <span>{(configusr.activeuser) ? configusr.activeuser.email : ''}</span>
                </div>
            </div>
            <div className="userPic">
                <span className="contentUserPic">{(configusr.activeuser) ? getLetter(configusr.activeuser.email) : ''}</span>
            </div>
        </header>
    );
}

export default Header;