import React, {useState} from 'react'

const ContextGeneral = React.createContext({})

export function GeneralContext({children}) {
    const [ sktoken, setSktoken ] = useState(
        () => localStorage.getItem('_WT_SKT')
    )

    const [ hpytoken, setHpytoken ] = useState(
        () => localStorage.getItem('_WT_HPY')
    )

    const [ sstoken, setSstoken ] = useState(
        () => localStorage.getItem('_WT_SS')
    )

    const [ config_usr, setConfigUser ] = useState(
        () => localStorage.getItem('_WTCG')
    )

    return <ContextGeneral.Provider value={{sktoken, setSktoken, hpytoken, setHpytoken, sstoken, setSstoken, config_usr, setConfigUser}}>
        {children}
    </ContextGeneral.Provider>
}

export default ContextGeneral