import React, { useState, useEffect, useRef } from "react";
import useGeneral from '../../../src/hooks/useGeneralContext';
import ICONS from '../layout/icons/icons';
import { NavLink } from 'react-router-dom';
import axios from "axios";
import Header from "./Header";
import Footer from "./Footer";

const TopNav = ({children}) => {

    const[isOpen ,setIsOpen] = useState(true);
    const [isToggleActive, setIsToggleActive] = useState(false);
    const sidebarRef = useRef(null);
    const toggleButtonRef = useRef(null);

    const toggle = () => setIsOpen (!isOpen);

    const {
        sk,
        hpy,
        ss,
        logout
    } = useGeneral();

    const handleSignOut = () => {
        axios.delete(process.env.REACT_APP_WAREHOUSE+'logout', {
            data: {},
            params: {
            },
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer '+hpy+'||'+ss+'||'+sk
            }
        }).then(function (response) {
            logout()
        }).catch(function (error) {
        })
    }

    const menuItem=[
        {
            path:"/",
            name:"Dashboard",
            icon:ICONS['menu']['inventory']
        },
        {
            path:"/recibos",
            name:"Recibos",
            icon:ICONS['menu']['warehouse-in']
        },
        {
            path:"/salidas",
            name:"Salidas",
            icon:ICONS['menu']['warehouse-out']
        }
    ];

    useEffect(() => {
        console.log(isOpen);
        const handleClickOutside = (event) => {
            if (sidebarRef.current &&
                !sidebarRef.current.contains(event.target) &&
                toggleButtonRef.current &&
                !toggleButtonRef.current.contains(event.target)) {
                if (!isOpen) {
                    toggle();
                }
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isOpen]);

    useEffect(() => {
        const handleResize = () => {
            if(window.innerWidth >= 860){
                setIsToggleActive(true);
                setIsOpen(true);
            }else{
                setIsToggleActive(false);
                setIsOpen(false);
            }
        };

        window.addEventListener('resize', handleResize);

        handleResize();

        return () => {
          window.removeEventListener('resize', handleResize);
        };
      }, []);

    return (
        <div className="container-nav">
           <div ref={sidebarRef} className={`sidebar ${isOpen ? 'isClosed' : ''}`}>
                <div className="top_section" >
                </div>
               {
                   menuItem.map((item, index)=>(
                       <NavLink to={item.path} key={index} className="link" activeclassname="active">
                           <div className="icon">{item.icon}</div>
                           <div className="link_text">{item.name}</div>
                       </NavLink>
                   ))
               }
                <div className="link" type='button' onClick={()=>handleSignOut()}>
                    <div className="icon">{ICONS['menu']['close']}</div>
                    <div className="link_text">Cerrar Sesion</div>
                </div>
           </div>
               {
                typeof isOpen !== 'undefined' ?
                <main>
                    <Header isOpen={isOpen} toggle={toggle} toggleButtonRef={toggleButtonRef}/>
                    <div className={`bodyContainer ${isOpen ? '' : 'isClosed'}`}>
                        {children}
                    </div>
                    <Footer isOpen={isOpen} toggle={toggle} />
                </main>
                    :
                    ''
               }
        </div>
      );
};

export default TopNav;