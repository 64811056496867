import React from "react";
import '../../assets/css/style-header.css';
import logowhite from '../../assets/img/logo-weport-white.png';

const Footer = () => {
    
    return(
        <footer className="footer d-flex align-items-center bg-purple">
            <div className="container-fluid">
                <div className="row align-items-center">
                    <div className="col-12 text-center">
                        <img src={logowhite} alt="WETRACK" className="logowhite" />
                    </div>

                    <div className="col-12 text-center">
                        {/**<ul className="social-links mb-3">
                            <li><a href=""><RiFacebookBoxLine size={25} /></a></li>
                            <li><a href=""><RiInstagramLine size={25} /></a></li>
                            <li><a href=""><RiLinkedinBoxLine size={25} /></a></li>
                        </ul>*/}
                        <p className="text-white pb-4 pt-4 pb-lg-0 pt-lg-0">&copy;Copyright 2023 by WEPORT S.A. DE C.V.</p>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;